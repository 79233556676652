// 
// Custom pygments syntax highlight theme, based mostly on Monokai
//

.highlight pre .hll { background-color: #49483e }
.highlight pre  { background: #272822; color: #f8f8f2 }
.highlight pre .c { color: #75715e } /* Comment */
.highlight pre .err { color: #960050; background-color: #272822 } /* Error */
.highlight pre .k { color: #c73237 } /* Keyword */
.highlight pre .l { color: #ae81ff } /* Literal */
.highlight pre .n { color: #f8f8f2 } /* Name */
.highlight pre .o { color: #dbdabd } /* Operator */
.highlight pre .p { color: #f8f8f2 } /* Punctuation */
.highlight pre .ch { color: #75715e } /* Comment.Hashbang */
.highlight pre .cm { color: #75715e } /* Comment.Multiline */
.highlight pre .cp { color: #75715e } /* Comment.Preproc */
.highlight pre .cpf { color: #75715e } /* Comment.PreprocFile */
.highlight pre .c1 { color: #75715e } /* Comment.Single */
.highlight pre .cs { color: #75715e } /* Comment.Special */
.highlight pre .gd { color: #f92672 } /* Generic.Deleted */
.highlight pre .ge { font-style: italic } /* Generic.Emph */
.highlight pre .gi { color: #a6e22e } /* Generic.Inserted */
.highlight pre .gs { font-weight: bold } /* Generic.Strong */
.highlight pre .gu { color: #75715e } /* Generic.Subheading */
.highlight pre .kc { color: #c73237 } /* Keyword.Constant */
.highlight pre .kd { color: #c73237 } /* Keyword.Declaration */
.highlight pre .kn { color: #f92672 } /* Keyword.Namespace */
.highlight pre .kp { color: #c73237 } /* Keyword.Pseudo */
.highlight pre .kr { color: #c73237 } /* Keyword.Reserved */
.highlight pre .kt { color: #c73237 } /* Keyword.Type */
.highlight pre .ld { color: #e6db74 } /* Literal.Date */
.highlight pre .m { color: #ae81ff } /* Literal.Number */
.highlight pre .s { color: #e6db74 } /* Literal.String */
.highlight pre .na { color: #a6e22e } /* Name.Attribute */
.highlight pre .nb { color: #f8f8f2 } /* Name.Builtin */
.highlight pre .nc { color: #a6e22e } /* Name.Class */
.highlight pre .no { color: #c73237 } /* Name.Constant */
.highlight pre .nd { color: #a6e22e } /* Name.Decorator */
.highlight pre .ni { color: #f8f8f2 } /* Name.Entity */
.highlight pre .ne { color: #a6e22e } /* Name.Exception */
.highlight pre .nf { color: #a6e22e } /* Name.Function */
.highlight pre .nl { color: #f8f8f2 } /* Name.Label */
.highlight pre .nn { color: #f8f8f2 } /* Name.Namespace */
.highlight pre .nx { color: #ccc    } /* Name.Other */
.highlight pre .py { color: #f8f8f2 } /* Name.Property */
.highlight pre .nt { color: #f92672 } /* Name.Tag */
.highlight pre .nv { color: #f8f8f2 } /* Name.Variable */
.highlight pre .ow { color: #dbdabd } /* Operator.Word */
.highlight pre .w { color: #f8f8f2 } /* Text.Whitespace */
.highlight pre .mb { color: #8cd0d3 } /* Literal.Number.Bin */
.highlight pre .mf { color: #8cd0d3 } /* Literal.Number.Float */
.highlight pre .mh { color: #8cd0d3 } /* Literal.Number.Hex */
.highlight pre .mi { color: #8cd0d3 } /* Literal.Number.Integer */
.highlight pre .mo { color: #8cd0d3 } /* Literal.Number.Oct */
.highlight pre .sa { color: #e6db74 } /* Literal.String.Affix */
.highlight pre .sb { color: #e6db74 } /* Literal.String.Backtick */
.highlight pre .sc { color: #e6db74 } /* Literal.String.Char */
.highlight pre .dl { color: #e6db74 } /* Literal.String.Delimiter */
.highlight pre .sd { color: #e6db74 } /* Literal.String.Doc */
.highlight pre .s2 { color: #e6db74 } /* Literal.String.Double */
.highlight pre .se { color: #8cd0d3 } /* Literal.String.Escape */
.highlight pre .sh { color: #e6db74 } /* Literal.String.Heredoc */
.highlight pre .si { color: #e6db74 } /* Literal.String.Interpol */
.highlight pre .sx { color: #e6db74 } /* Literal.String.Other */
.highlight pre .sr { color: #e6db74 } /* Literal.String.Regex */
.highlight pre .s1 { color: #e6db74 } /* Literal.String.Single */
.highlight pre .ss { color: #e6db74 } /* Literal.String.Symbol */
.highlight pre .bp { color: #f8f8f2 } /* Name.Builtin.Pseudo */
.highlight pre .fm { color: #a6e22e } /* Name.Function.Magic */
.highlight pre .vc { color: #f8f8f2 } /* Name.Variable.Class */
.highlight pre .vg { color: #f8f8f2 } /* Name.Variable.Global */
.highlight pre .vi { color: #f8f8f2 } /* Name.Variable.Instance */
.highlight pre .vm { color: #f8f8f2 } /* Name.Variable.Magic */
.highlight pre .il { color: #ae81ff } /* Literal.Number.Integer.Long */

.css .o,
.css .o + .nt,
.css .nt + .nt { color: #999; }