// 
// Table of contents 
//

.toc {
  font-family: $sans-serif-narrow;
  color: $gray-3;
  background-color: $body-bg;
  border-radius: 4px;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.5);
  
  header {
    border: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-title {
    margin: 0;
    color: #fff;
    background: $gray-6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;   
  }

  // Scrollspy marks toc items as .active when they are in focus
  //.active {
  //  background: #C1C1C1;
  //  background: rgba(193, 193, 193, 0.6);
  //  font-weight: 600;
  //}
  
  .section-nav {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    border: 1px solid #f2f3f3;
    border-radius: 4px;

    //@include breakpoint($large) {
    //  font-size: 0.8rem;
    //}

    a {
      display: block;
      color: $gray-6;
      font-weight: 500;
      border-bottom: 1px solid $border-color;

      &:hover {
        color: $link-color;
      }
    }
    li ul > li a {
      font-weight: normal;
    }  
  }
}

@media (max-width: 70rem) {
  .sidebar-right {
    display:none;
  }
  .page-top, .post-top {
    display:block;
    padding: 1em 2em;
    
    .nav-title {
       font-size: 0.9rem;
       padding: 0.5rem 1rem;
    }
    .section-nav {
      font-size: 0.8rem;
      
      a {
        padding: 0.25rem 1rem;
        line-height: 1.4;
      }    
      li ul > li a {
        padding-left: 2rem;
      }
      li ul li ul > li a {
        padding-left: 3rem;
      }
      li ul li ul li ul > li a {
        padding-left: 4rem;
      }
      li ul li ul li ul li ul > li a {
        padding-left: 5rem;
      }
      li ul li ul li ul li ul li ul > li a {
        padding-left: 6rem
      }      
    }
  }
}

@media (min-width: 70rem) {
  .page-top, .post-top {
    display:none;
  }
  .sidebar-right {
    display:block;
    position: sticky ;
    float: right;
    top: 0;
    right: 0;
    width: 220px;
    margin-right: -220px;
    padding-left: 1.4em;
    padding-top: 1em ;
    z-index: 10;
    
    .nav-title {
       font-size: 0.8rem;
       padding: 0.3rem 0.5rem;
    }
    .section-nav {
      font-size: 0.7rem;
      
      a {
        padding: 0.25rem 0.5rem;
        line-height: 1.2;
      }
      li ul > li a {
        padding-left: 1rem;
      }
      li ul li ul > li a {
        padding-left: 1.5rem;
      }
      li ul li ul li ul > li a {
        padding-left: 2rem;
      }
      li ul li ul li ul li ul > li a {
        padding-left: 2.5rem;
      }
      li ul li ul li ul li ul li ul > li a {
        padding-left: 3rem
      }
    }
  }
}

