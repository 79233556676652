// Pagination
//
// Super lightweight (HTML-wise) blog pagination. `span`s are provide for when
// there are no more previous or next posts to show.

.pagination {
  overflow: hidden; // clearfix
  margin: 3rem 0;
  color: #ccc;
  text-align: center;
  
  //Pagination items can be `span`s or `a`s
  .pagination-item {
    display: block;
    float: left;
    width: 50%;
    padding: 1rem;
    border: solid #eee;
    border-width: 1px;
    

    &:first-child {
      margin-bottom: 0;
      border-top-left-radius:    4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      margin-left: -1px;
      border-top-right-radius:    4px;
      border-bottom-right-radius: 4px;
    }
  }
  // Only provide a hover state for linked pagination items
  a.pagination-item {
    color: $gray-6;
    &:hover {
      background-color: #f5f5f5;   
    }
  }
}