$gray-1: #f9f9f9;
$gray-2: #ccc;
$gray-3: #767676;
$gray-4: #515151;
$gray-5: #313131;
$gray-6: #343a40;

$red: #ac4142;
$red2: #DA4453;
$orange: #d28445; 
$yellow: #f4bf75;
$green: #90a959;
$cyan: #75b5aa;
$blue: #268bd2;
$blue2: #6a9fb5;
$brown: #8f5536;

$root-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
$root-font-size: 15px;
$root-line-height: 1.5;

$sans-serif-narrow: -apple-system,BlinkMacSystemFont,"Roboto","Segoe UI","Helvetica Neue","Lucida Grande",Arial,sans-serif;
$box-shadow: 0 1px 1px rgba(0,0,0,0.125);

$body-color: #515151;
$body-bg: #fdfdfd;
$link-color: $red2;

$border-color: #e5e5e5;

$large-breakpoint: 45em;
$large-font-size: 16px;

$code-font-family: Monaco, "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
$code-font-family-2: Menlo, Monaco, "Courier New", monospace;
$code-color: #bf616a;
