//
// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. 
// Each page is wrapped in `.page` and is only used on the page layout.
//

.page,
.page-note,
.post {
  margin-bottom: 3em;
  
  li + li {
    margin-top: .25rem;
  }
  
  p + ul {  
    margin-top: -0.5rem;
  }
  
  img {
    clear: both;
    display: block;
    margin: 0 auto;    
  }
  
  .aligncenter {
    clear: both;
    display: block;
    margin: 0 auto;
  }
}

.list-group {
  .list-group-item {
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
  }
}
li.list-group-item + li.list-group-item {
  margin-top: .8rem;
}

.post-content,
.page-content {
  text-align: justify;
}

.page-note,
.post {
  h1, h2, h3, h4, h5 {
    border-bottom: 1px solid #DDD;
    line-height: 1.5;
    text-align: left;
  }
}
  
// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: #303030;
}
.page-title,
.post-title {
  margin-top: 0;
}

.page-header,
.post-header {
  text-align: left;
  margin-bottom: 0.5rem; 
  
  span {
    padding-right:0.5rem;
  } 
  .post-date {  
    time {
      font-size: 0.9rem;
      color: #9a9a9a;
    }    
  }
  .page-cats, .post-cats {
    a {
      font-size: 0.9rem;
      color: #9a9a9a;
    }
  }
  .page-tags, .post-tags {
    a {
      padding: 6px 7px;
      margin-right: 0;
      margin-bottom: 4px;
      line-height: 100%;
      display: inline-block;
      color: #FFF;
      background-color: #C1C1C1;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      font-size: 0.7rem;
      text-decoration: none;
      
      &:hover, &:focus {
        background-color: #da4453cc;
        text-decoration: none;      
      }
    }
  }
  
  @media screen and (max-width: 480px) {
    .page-cats, .page-tags, .post-cats, .post-tags {
      display: block;
    }
  }
}


// Related posts
.related {
  h2 {
    margin-top: 0;
  }
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid #eee;
  //border-bottom: 1px solid #eee;
}

.related-posts {
  padding-left: 0;
  list-style: none;

  li {
    small {
      font-size: 75%;
      color: #999;
    }

    a:hover {
      color: #268bd2;
      text-decoration: none;

      small {
        color: inherit;
      }
    }
  }
}
