// 
// Search page
//

.search {
  
  .search-box {
    width: 100%;
    position: relative;
    display: flex;
    margin: 0 0 1.5rem 0;
    padding: .5rem 1.5rem;
    
    .search-term {
      width: 100%;
      border: 3px solid #343a40;
      border-right: none;
      padding: 5px;
      height: 38px;
      border-radius: 5px 0 0 5px;
      outline: none;
      color: #808B96;
    }

    .search-term:focus {
      color: #000;
    }

    .search-button {
      width: 40px;
      height: 38px;
      border: 1px solid #343a40;
      background: #343a40;
      text-align: center;
      color: #fff;
      border-radius: 0 5px 5px 0;
      font-size: 20px;         
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }  
}
