// Math and MathJax styles
//

.MathJax {
  font-size: 0.85rem !important;
}
.MathJax_Display {
  font-size: 0.75rem !important;
  //padding: 0.5em;
  margin: 0.8rem 0 !important;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
}
.MathJax_Display .MathJax {
  display: inline-block;
  width: 100%;
}   

.definition,
.example,
.lemma,
.proof,
.theorem {
  display: block;
  margin: 1rem 0;
  text-align: justify;
  
  /* Little fix for raw HTML and kramdown interaction issue */
  p:first-child {
    display: inline;
  }
  p:first-child::before {
    content: &nbsp ;
  }
  p:first-child + ul, 
  p:first-child + ol,  {  
    margin-top: 0.5rem;
  }
}
  
.definition,
.lemma,
.theorem {
  font-size: 1em
}

.theorem {
  font-style: italic
}
.theorem::before {
  content: "Teorema " attr(data-number) attr(data-name) ". ";
  font-style: normal;
  font-weight: 700;
  color: #313131;
}
  
.lemma {
  font-style: italic
}
.lemma::before {
  content: "Lema " attr(data-number) attr(data-name) ". ";
  font-style: normal;
  font-weight: 700;
  color: #313131;
}

.proof {
  font-style: normal;
  overflow: hidden
}
.proof p:last-of-type,
.proof p:last-of-type .MathJax_Display:last-of-type {
  margin-bottom: 0
}
.proof::before {
  content: "Demostraci\F3n. ";
  font-style: italic;
  font-weight: 700;
  color: #313131;
}  
.proof::after {
  content: "\20DE";
  font-size: 1.2em;
  font-weight: 500;
  float: right;
  margin-top: -5px;
  padding: 0
}

.qed {
  float: right;
  font-size: 1.2em;
  
  &:before{
    content: "\20DE";
  }
}

.definition {
  font-style: italic
}
.definition::before {
  content: "Definici\F3n " attr(data-number) attr(data-name) ". ";
  font-style: normal;
  font-weight: 700;
  color: #313131;
}

.example {
  font-style: normal
}
.example:before {
  content: "Ejemplo " attr(data-number) ". ";
  font-style: normal;
  font-weight: 700;
  color: #313131;
}
