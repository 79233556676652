// Layout
//
// Styles for managing the structural hierarchy of the site.


.content {
  height: 100%;
}

.container {
  max-width: 45rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.home {
  margin-bottom: 4rem; 
}

footer {
  margin-top: auto;
  background-color: $gray-6;  //old 313233
  color: #999;
  font-size: 0.75rem;
  
  p, ul {
    margin-bottom: 0;
  }

  .social-links,
  .ad-free,
  .copyright {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
  
  .list-inline-item:not(:last-child) {
    margin-right: .2rem;
  }
  
  @media only screen and (min-width: 15rem) {
    .social-links .list-inline {
      font-size: 13px;
    }
  }
}
