// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  //padding-top:    1rem;
  //padding-bottom: 1rem;
  margin-bottom: 3rem;
  background-color: #343a40;
}


.masthead-title {
  margin-top: 0;
  margin-bottom: 0;
  color: $gray-4;

  a {
    color: inherit;
  }

  small {
    font-size: 75%;
    font-weight: 400;
    opacity: .5;
  }
}

nav.navbar {
    
  .navbar-brand {
    margin-right: 0px;
    
    .brand-anim {
        
      margin-top: 5px;
      
      @media screen and (max-width: 480px) {
        width:55vw! important;
        height: auto;
        margin-top:10px;
      }
    }
  }
  
/*   .dropdown > .nav-link {
    cursor: default;
  }   */

  .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
  }

  .dropdown-item {
    color: rgba(255,255,255,.5);
    
    &:hover {
      background: #343a40;
      color: #FFF;
    }   
    span {
      margin-left: 1rem;
    }    
  }
  
  .dropdown-item:active,
  .dropdown-item.active {
    color: #fff;
    background: #343a40;
  }
    
  ul li a {
    position: relative;
    font-weight: 500;    // TODO: no se ve muy bold en firefox, difiere de chrome 
    
    &:after {
      transition: all .5s;
      position: absolute;
      bottom: 0;
      left: 0; 
      right: 0;
      margin: auto;
      width: 0%;
      content: ".";
      color: transparent;
      background: #da4453cc;
      height: 1.5px;          
    }
    
    &:hover {
      color: #FF0000;
    }
    
    &:hover:after {
      width: 100%;
    }
  }
  
  @media (min-width: 992px) {
    .nav-link {
      padding-right: .8rem !important;
      padding-left: .8rem !important;
    }
  }
  
  .nav-link:hover {
    color: #FFF !important;
  }
}



