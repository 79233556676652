// Code
//
// Inline and block-level code snippets. Includes tweaks to syntax highlighted
// snippets from Pygments/Rouge and Gist embeds.

code,
pre {
  font-family: $code-font-family;
}

code {
  padding: .25em .5em;
  font-size: 85%;
  color: $code-color;
  background-color: #f9f9f9;
  border-radius: 3px;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
}

pre code {
  padding: 0;
  font-size: 100%;
  color: inherit;
  background-color: transparent;
}

// Pygments/Rouge via Jekyll
.highlight {
  font-size: 1rem;
  
  .rouge-table {        // cuando hay numeracion   
    font-size: 1rem;
    margin-bottom: 0;
    border: none;    
    .gl {
      padding: 0 10px 0 0;
    }
    td {
      border: none;
      padding: 0;
    }
  }
  
  pre {
    margin-bottom: 0;
  }
  
  > pre {
    
    overflow-x: auto;
    padding: 10px 0 10px 10px;
  }

  .lineno {
    //display: inline-block; // Ensures the null space also isn't selectable
    border-right: 2px solid #3185b9;
    padding-right: .75rem;
    //padding-left: .25rem;
    text-align: right;
    color: #afafaf;
    // Make sure numbers aren't selectable
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}


// Gist via GitHub Pages
// .gist .gist-file {
//   font-family: Menlo, Monaco, "Courier New", monospace !important;
// }
// .gist .markdown-body {
//   padding: 15px;
// }
// .gist pre {
//   padding: 0;
//   background-color: transparent;
// }
// .gist .gist-file .gist-data {
//   font-size: .8rem !important;
//   line-height: 1.4;
// }
// .gist code {
//   padding: 0;
//   color: inherit;
//   background-color: transparent;
//   border-radius: 0;
// }
